$background-detail-page: #f3f5f7;
$background-border-detail-page: #EBEEF1;
$title-color: #3047EC;
$slogan-color: #02B875;
.container {
  max-width: 940px;
  width: calc(100% - 40px);
  margin: auto;
}
.container-wrapper {
  margin: 0 20px;
}
.help-center{
    padding: 0;
    margin: 0;
    background:$background-detail-page;
}
.help-header{
    width: 100%;
    height: 250px;
    margin-bottom: 35px;
}

.help-section{
    background:#f3f5f7 ;
    height:100%;
    padding-bottom: 20px;
}