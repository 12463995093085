.faq-search-box {
  .search-faqBox {
    height: 65px;
    background: rgba(255,255,255,0.2);
    transition: 300ms;
    color: #434343;
    border: 0px;
    padding-left: 24px;
    border-radius: 5px;
    font-size: 16px;
    &:focus {
      background: rgba(255,255,255,1);
      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color:#4343;
        opacity: 1; /* Firefox */
      }
    }
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color:#EEE;
      opacity: 1; /* Firefox */
    }
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color:#EEE;
      opacity: 1; /* Firefox */
    }
  }
}
.header-faq {
  background-image: url("/images/faq-header-banner.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 250px;
  .brand-title {
    display: flex;
    justify-content: center;
    padding-top: 25px;
    color: white;
    // div {
    //   margin-top: 20px;
    //   width: 900px;
    //   color: white;
    //   display: flex;
    //   flex-direction: row;
    .brand-title-logo {
      width: 50%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }
    .brand-title-link {
      width: 50%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }

  .greeting-title {
    margin-bottom: 25px;
    width: 100%;
    span {
      width: 900px;
      color: #fff;
      font-size: 25px;
      font-weight: 600;
    }
  }
}