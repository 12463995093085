$section-background-color: #f3f5f7;
$item-max-height: 175px;

.help-items {
  height: $item-max-height;
  background: #fff;
  color: #565867;
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
  display: flex;
  height: 150px;
  .help-item-icon {
    width: $item-max-height;
    height: $item-max-height;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .help-item-content {
    width: 80%;
    height: 50px;
    margin-top: 28px;
    margin-bottom: 30px;
    margin-left: 10px;
    padding-right: 20px;
    height: $item-max-height - 23 - 30;
    .help-item-title {
      line-height: 22px;
      width: auto;
      font-size: 20px;
      margin-bottom: 5px;
    }
    .help-item-des {
      line-height: 22px;
      font-size: 16px;
    }
    .help-item-info {
      line-height: 40px;
    }
  }
}
.help-list a {
  padding: 0;
  margin: 0;
}

// FAQ list
.faq-collection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .faq-cat-item {
    background: white;
    width: 100%;
    margin-bottom: 18px;
    border-radius: 5px;
    box-shadow: 0 3px 8px 0 rgba(0,0,0,0.03);
    transition: 300ms;
    border: 1px solid transparent;
    &:hover {
      border: 1px solid rgba(136,149,162,0.2);
      background-color: #fcfcfc;
    }
  }
  // @media screen and (max-width: 1400px) {
    // .collection-items {
    //   height: auto;
    //   background: #fff;
    //   color: #565867;
    //   width: 900px;
    //   margin: auto;
    //   display: flex;
    //   box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.05);
    //   border-radius: 5px;

    // }

    .collection-icon {
      width: 175px;
      height: 175px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
    }
    .collection-content {
      width: 80%;
      height: 50px;
      margin-top: 28px;
      margin-bottom: 30px;
      margin-left: 185px;
      padding-right: 20px;
      height: $item-max-height - 23 - 30;
      .collection-title {
        line-height: 25px;
        width: auto;
        font-size: 18px;
        color: #02b875;
        margin-bottom: 5px;
      }
      .collection-description {
        line-height: 22px;
        font-size: 14px;
        color: #565867;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;
      }
      .collection-preview {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        .writer-avt {
          list-style: none;
          display: flex;
          flex-direction: row;
          margin-left: -40px;
        }
        .about-writer {
          margin-left: 15px;
          margin-top: -10px;
          span {
            font-size: 12px;
            line-height: 15px;
            color: grey;
          }
          div {
            display: flex;
            flex-direction: row;
            font-size: 12px;
            line-height: 15px;
            color: grey;
            p {
              font-size: 12px;
              line-height: 15px;
              color: grey;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }

// FAQ Collelction list
.faq-backlink {
  color: #4f5e6b;
  margin: 12px 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  .anticon {
    font-size: 11px;
    padding: 0px 7px;
    line-height: 25px;
    color: #8f919d;
  }
  a {
    color: #5c6a76 !important;
  }
  a:hover {
    color: #02b875 !important;
  }
  span {
    color: #aaa;
  }
}
// @media screen and (max-width: 1500px) {
.faq-collection-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;

  

  .FAQCollectionList {
    background: #ebeef1;
    margin-bottom: 15px;
    .FAQCollectionList-header {
      margin: 10px 30px;
      margin-top: 30px;
     
      .FAQCollection-items {
        color: #565867;
        display: flex;
        height: auto;
        border-radius: 5px;
        .FAQCollection-icon {
          width: $item-max-height;
          height: $item-max-height;
          display: flex;
          justify-content: center;
          align-items: center;
          img{
            max-width: 100%;
            max-height: 100%;
          }
        }
        .FAQCollection-content {
          width: 80%;
          height: auto;
          margin-top: 28px;
          margin-bottom: 30px;
          margin-left: 10px;
          padding-right: 20px;
          .FAQCollection-title {
            line-height: 25px;
            width: auto;
            font-size: 20px;
            color: #02b875;
            margin-bottom: 5px;
          }
          .FAQCollection-description {
            font-size: 14px;
          }
          .FAQCollection-preview {
            margin-top: 10px;
            display: flex;
            flex-direction: row;
            .FAQCollection-writer-avt {
              list-style: none;
              display: flex;
              flex-direction: row;
              margin-left: -30px;
            }
            .FAQCollection-about-writer {
              margin-left: 15px;
              margin-top: -10px;
              span {
                font-size: 12px;
                color: grey;
              }
              ul {
                display: flex;
                flex-direction: row;
                font-size: 12px;
                color: grey;
                margin-left: -40px;
                li {
                  list-style: none;
                  font-size: 12px;
                  color: grey;
                  margin-left: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
}

// faq item
.FAQItem-list {
  padding-bottom: 40px;
  width: 100%;
  .FAQItem {
    height: auto;
    min-height: 150px;
    padding-bottom: 20px;
    background: #fff;
    margin: 2px 30px;
    padding: 20px 30px;
    .FAQItem-title {
      line-height: 25px;
      width: auto;
      font-size: 20px;
      color: #02b875;
      margin-bottom: 5px;
    }
    .FAQItem-description {
      line-height: 22px;
      font-size: 13px;

      color: #565867;
      margin-bottom: 10px;
    }
    
  }
}
.FAQItem-about-author {
  display: flex;
  flex-direction: row;
  .FAQItem-about-article {
    display: flex;
    flex-direction: column;
    span {
      color: #8f919d;
      font-size: 13px;
      display: flex;
      flex-direction: row;
      p {
        color: #5c6a76;
        font-size: 11px;
        margin-left: 4px;
        margin-top: 2px;
      }
    }
    p {
      color: #8f919d;
      font-size: 11px;
      margin-top: -14px;
    }
  }
}
.search-result {
  width: 100%;
  .FAQItem {
    margin: 0px;
    margin-bottom: 12px;
    border-radius: 4px;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
    transition: 300ms;
    border: 1px solid transparent;
    &:hover {
      border: 1px solid rgba(136,149,162,0.2);
      background-color: #fcfcfc;
    }
  }
}

//pagination
.pagination {
  display: flex;
  flex-direction: row;
  .page-item {
    transition: ease 0.5s;
    padding: 8px;
    .page-link {
      padding: 14px;
      color: #565867;
      font-size: 15px;
    }
  }
  .page-item:hover {
    background: #02b875;
    .page-link {
      color: #fff;
    }
  }
}
.faq-content {
  padding: 60px 80px;
  background: white;
  color: #434343;
  .faq-content-title {
    color: #02b875;
    font-size: 28px;
    font-weight: 400;
  }
  .faq-content-description {
    color: #8F919D;
  }
  img {
    max-width: 100%;
  }
}
.cat-image {
  max-width: 100%;
  max-width: 100%;
}

// XS (phones)
@media screen and (max-width: 768px) {
  .container {
    width: calc(100% - 20px);
  }
  .faq-collection {
    .collection-icon {
      display: none;
    }
    .collection-content {
      margin-left: 20px;
      .collection-description {
        font-size: 13px;
      }
    }
  }
  .faq-content {
    padding: 24px;
    .faq-content-title {
      font-size: 20px;
    }
  }
}